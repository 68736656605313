<template>
  <div class="poster-page page-all-in" v-if="idList.length > 0">
    <div class="show-area">
      <!-- <p class="is-blank">{{ countData>=0 ? `${countData}/${idList.length} 加载中···` : '无内容' }}</p> -->
      <p class="is-blank" v-if="countData<idList.length">{{ countData>=0 ? `${countData}/${idList.length} 加载中···` : '无内容' }}</p>
      <div class="is-list" v-else>
        <h1 :class="countFile>0?'':'is-loading'"><span
        >图片生成 {{countFile}}/{{countData}}</span><slot v-if="countFile>0"
        ><i>&emsp;</i><button @click="toDownloads"
        >点击下载全部图片</button> 或 点击图片单独下载：</slot></h1>
        <img v-for="(item,index) in files" :src="item.file" alt="" :key="'invite_pre_'+index" @click="toDownloads(item.file, item.name)">
      </div>
    </div>
    <slot v-if="countData === idList.length">
      <Renders
        v-for="id in idList"
        :_selectparams="renderParam(id)"
        :_activedata="actFileData(id)"
        :_origindata="_datalist[id]"
        :key="_paramlist.type+_paramlist.cardid+id"
        :class="'card-hide '+(_paramlist.type+_paramlist.cardid+id)"
        @isready="setCardInfo"
      ></Renders>
    </slot>
  </div>
</template>

<script>
import html2canvas from 'html2canvas';
import mixinPosterBase from "../../utils/mixinPosterBase.js";
import { getCardInfo } from '../../utils/api.js';

export default {
  name: 'PosterPages',
  mixins: [ mixinPosterBase ],
  components: {
    // Icon: ()=>import('../Icon.vue'),
    Renders: ()=>import('./Renders.vue'),
  },
  props: {
    _indexlist: {
      type: [Array, Object],
      default: () => {}
    },
    _paramlist: {
      type: [Array, Object],
      default: () => {}
    },
    _datalist: {
      type: [Array, Object],
      default: () => {}
    },
  },
  computed: {
    idList () {
      return (this._paramlist && this._paramlist.id) || []
    }
  },
  data () {
    return {
      files: {},
      fileData: {},
      countFile: 0,
      countData: 0,
    }
  },
  watch: {
    idList: {
      handler (newValue, oldValue) {
        if (newValue && newValue.length > 0 && !this.global.toCompareObject(newValue, oldValue)) {
          this.files = {}
          this.fileData = {}
          this.countFile = 0
          this.countData = 0
          this.acquireActiveData(newValue)
        }
      },
      immediate: true,
      deep: true
    },
  },
  methods: {
    renderParam (id) {
      let obj = {
        cardDetailId: '',
        cardId: this._paramlist.cardid,
        sid: this._paramlist.sid,
        type: this._paramlist.type,
        year: this._paramlist.year,
      }
      let keys = {course:'courseId', subject:'venueId', chairman:'lecturerId', producer:'lecturerId'}
      let sKey = this.secondaryKeys[obj.type] || ''
      if (keys[obj.type]){
        obj[keys[obj.type]] = id.split('_')[0]
        if (sKey) {
          obj[sKey] = id.split('_')[1] || ''
        }
      }
      return obj
    },
    actFileData (id) {
      let obj = this.global.deepClone((this.fileData && this.fileData[id]) || {})
      obj.allIn = true
      return obj
    },
    async acquireActiveData (idlist) {
      if (idlist && idlist.length > 0) {
        // let type = this._paramlist.type
        let param = this.global.deepClone(this._paramlist)
        for (let id of idlist) {
          param.id = id.split('_')[0]
          await this.getActiveData(param).then(res => {
            if (res && res.cardId) {
              // console.log(param.id, res)
              // this.activeData += '"' + param.id + '":'
              let data = this.global.deepClone(res)
              data.paramId = id
              // this.activeData += JSON.stringify(data)
              // this.activeData += ','
              this.fileData[id] = this.global.deepClone(data)
              this.countData ++
            }
          })
        }
      }
    },
    getActiveData(_params) {
      return new Promise((resolve, reject) => {
        // console.log('Poster·method·get_active_data ', _params);
        if(_params.sid && _params.cardid && _params.id) {
          let params = `?sid=${_params.sid}&cardId=${_params.cardid}`;
          if (_params.type==='course') {
            params += `&courseId=${_params.id||''}`
          } else if (_params.type==='subject') {
            params += `&venueId=${_params.id||''}`
          } else if (['chairman','producer'].includes(_params.type)) {
            params += `&lecturerId=${_params.id||''}`;
          }
          // console.log('Poster·method·get_active_data ', getCardInfo + params);
          this.$http.jsonp(getCardInfo + params)
          .then(res => {
            return res.json();
          })
          .then(res => {
            // console.log('Poster·method·get_active_data------ ', res);
            if(res && res.status=='200' && res.data) {
              resolve(res.data)
              return
            }
            resolve(false)
          }).catch(err => {
            resolve(false)
          })
        }
      })
    },
    toDownloads (_file, _name) {
      let url = []
      let name = []
      if (_file) {
        url.push(_file)
        name.push(_name + '.png')
      } else {
        let arr = Object.values(this.files)
        for (let item of arr) {
          if (item.file && item.name) {
            url.push(item.file)
            name.push(item.name + '.png')
          }
        }
      }
      let path = '/downloads?url=' + encodeURIComponent(JSON.stringify(url)) + '&name=' + encodeURIComponent(JSON.stringify(name));
      console.log(path);
      if(this.global.IsPC()) {
        window.open(path, '_blank');
      } else {
        this.$router.push(path)
      }
    },
    setCardInfo (res) {
      console.log('👌🏻act-pages ', res);
      if(res && res.status && res.status == '200') {
        setTimeout(() => {
          const {type, id, secid} = res.data
          let options = {id, secid, type, cardid: this._paramlist.cardid}
          this.setCard(options); // 》》》》》》
        }, 500);
        this.$emit('setinfo', res.data);
      }
    },
    setCard (options) {
      // console.log('👌🏻re-pages ', options);
      if (options) {
        const {id, secid, type, cardid} = options
        let modelId = `#sharecard_model_${type}_${cardid}_${id}_${secid}`; // Renders.vue
        console.log(modelId);
        let canvasID = document.querySelector(modelId);
        html2canvas(canvasID, {
          useCORS: true, // 开启跨域配置
          // scale: window.devicePixelRatio < 3 ? window.devicePixelRatio : 2,
          scale: 2, // 将输出图像的分辨率设置为屏幕分辨率的两倍
          allowTaint: true, // 允许跨域图片
          dpi: 300, // 处理模糊问题
        }).then((canvas) => {
          // 获取年月日作为文件名-年月日加随机数
          var timers = new Date();
          var fullYear = timers.getFullYear();
          var month = timers.getMonth() + 1;
          var date = timers.getDate();
          var randoms = Math.random() + '';
          var theTime = fullYear + '' + ( month < 10 ? '0' : '' ) + month + ( date < 10 ? '0' : '' ) + date;
          let imgName = `share${theTime}${randoms.slice(3, 10)}`;
          /* dom */
          let dom = document.body.appendChild(canvas);
          dom.style.display = 'none';
          document.body.removeChild(dom);
          let imgBlob = this.global.dataURLToBlob(dom.toDataURL('image/png', 1.0));
          let imgUrl = window.URL.createObjectURL(imgBlob);
          /* url-blob */
          let fKey = `${id}${secid?'_':''}${secid||''}`
          this.files[fKey] = { file: imgUrl, name: imgName }
          this.countFile ++
          this.$forceUpdate()
          /* url-base64 */
          // let imgFile = new File([imgBlob], imgName, {
          //   type: 'image/png', // 'application/json',
          //   lastModified: Date.now()
          // });
          // this.global.triggerFile(imgFile).then(url => {
          //   if(url) {
          //     let fKey = `${id}${secid?'_':''}${secid||''}`
          //     this.files[fKey] = { file: url, name: imgName }
          //     this.countFile ++
          //     this.$forceUpdate()
          //   }
          // })
        })
      }
    }
  }
}
</script>

<style lang="scss">
.poster-page.page-all-in {
  width: 100%;
  height: 100%;
  max-width: calc(100vw - 430px);
  padding: 0;
  padding-left: calc(100vw - 430px);
  position: relative;
  z-index: 1;
  .show-area {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2;
    padding: 30px;
    max-height: calc(100vh - 60px);
    overflow: auto;
    .is-list {
      width: 100%;
      height: auto;
      min-height: calc(100vh - 120px);
      padding-top: 45px;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      h1 {
        font-size: 16px;
        position: absolute;
        top: 0;
        left: 0;
        button {
          color: #ffffff;
          padding: 2px 6px;
          font-size: 16px;
          font-weight: bolder;
          border-radius: 4px;
          border-color: #00C3FE70;
          background-color: rgba(240, 240, 240, 0.3);
          background: var(--background-color-button);
          // -webkit-background-clip: text;
          // background-clip: text;
          // -webkit-text-fill-color: transparent;
          opacity: 0.8;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
        }
        span {
          display: inline-block;
          color: #ffffff;
          background: var(--background-color-button);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      img {
        max-width: 500px;
        margin-right: 30px;
        margin-bottom: 30px;
        cursor: pointer;
      }
    }
    .is-blank, .is-list h1.is-loading {
      line-height: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: -60px;
      margin-bottom: 0;
    }
  }
  // 
  @media (max-width:767px) {
    max-width: 100vw;
    padding-left: 100vw;
    .show-area {
      position: fixed;
      padding: 15px;
      top: 60px;
      background-color: #f5f5f5;
      .is-list {
        padding-top: 60px;
        h1 {
          top: 15px;
          left: 15px;
          white-space: break-spaces;
          span {
            width: 100%;
          }
          i {
            display: none;
          }
        }
        img {
          max-width: 100%;
        }
      }
    }
  }
}
</style>